band:
  name: Bára Zmeková
  engineer:
    name: Dan Kadera
    phone: +420 773 111 988
    email: me@subsonic.cz
options:
  - type: section
    label: 'Lineup:'
    items:
      - id: lineup
        type: checklist
        options:
          - { label: Viktor / drums, value: vik, default: true }
          - { label: Pavel / bass, value: pavel, default: true }
          - { label: Michal / accordion, value: michal, default: true }
          - { label: Nina / violin, value: nina }
          - { label: Ondra / bassclarinet, value: ondra }
          - { label: Lenka / vocal, value: lenka }
  - type: section
    label: Options
    items:
      - id: piano
        type: checkbox
        label: piano
stage:
  areas:
    - id: left-arc
      type: arc
      x1: 0.075
      y1: 0.8
      $...:
        $if: { lineup: { $includes: lenka } }
        $then:
          x2: 0.175
          y2: 0.3
          r: 0.4
        $else:
          x2: 0.175
          y2: 0.425
          r: 0.6
    - id: back-line
      type: line
      x1: { $if: piano, $then: 0.475, $else: 0.45 }
      y1: 0.25
      x2: { $if: piano, $then: 0.875, $else: 0.7 }
      y2: 0.25
    - id: full-arc
      type: arc
      x1: 0.075
      y1: 0.85
      x2: 0.85
      y2: 0.225
      r: 0.65
  scale: { $if: piano, $then: 0.7, $else: 0.8 }
musicians:
  - id: arturia
    name: ''
    instruments: arturia
    sockets: 2
    mic:
      left: 50%
      top: 0
      transform: rotate(-180deg) translate(50%, 40%)
    placement:
      $if: piano
      $then: { x: 0.34, y: 0.35, a: 0 }
      $else: { x: 0.615, y: 0.7, a: 90 }
    minWidth: 120
    minHeight: 50
    inputs:
      - group: rhythm
        name: arturia
        count: 2
        note: 2x DI
  - id: piano
    name: Bára
    instruments: piano
    mic:
      left: 45%
      top: 100%
      transform: rotate(180deg) translate(-50%, 30%)
    addon:
      $if: { $not: { lineup: { $includes: vik } } }
      name: laptop
      position:
        left: 100%
        top: 100%
        transform: translate(-50%, 0) rotate(-90deg)
    placement: { x: 0.475, y: 0.55, a: 90 }
    minWidth: 140
    minHeight: 170
    inputs:
      - group: rhythm
        name: piano
        count: 2
        note: whichever mics & placement work best at the venue
    visible:
      $?: piano
    monitors:
      - iem: true
        group: bara
        stereo: true
        note: own wireless IEM
        $if: piano

  - id: steven
    name: Steven
    instruments: guitar
    mic: true
    placement:
      x: 0.3
      y: 0.85
    inputs:
      - group: band
        name: guitar
        note: 1x DI
      - group: vocals
        name: vocal Steven
        note: own SM58
    monitors:
      - group: mono
        note: floor monitor
        placement: { x: -0.15, y: 0.075 }

  - id: ondra
    name: Ondra
    instruments: bass clarinet
    placement: { $if: piano, $then: left-arc, $else: full-arc }
    inputs:
      - group: band
        name: bass clarinet
        note: own ATM350a clip-on mic
    monitors:
      - $if: { lineup: { $includes: 'ondra' } }
        iem: true
        group: dblpatch
        note: 1x XLR in
    visible:
      $?: { lineup: { $includes: 'ondra' } }

  - id: nina
    name: Nina
    instruments: violin
    mic: true
    placement: { $if: piano, $then: left-arc, $else: full-arc }
    inputs:
      - group: band
        name: violin
        note: own dpa 4099
      - group: vocals
        name: vocal Nina
        note: own ND96
    monitors:
      - $if: { lineup: { $includes: 'nina' } }
        iem: true
        group: dblpatch
        note: 1x XLR in
    visible:
      $?: { lineup: { $includes: 'nina' } }

  - id: lenka
    name: Lenka
    instruments: vocal
    mic: true
    placement: { $if: piano, $then: left-arc, $else: full-arc }
    inputs:
      - group: vocals
        name: vocal Lenka
        note: pref. Beta58
    monitors:
      - $if: { lineup: { $includes: lenka } }
        iem: true
        group: dblpatch
        note: 1x XLR in
    visible: { $?: { lineup: { $includes: lenka } } }

  - id: michal
    name: Michal
    instruments: accdn / synth
    sockets: 1
    placement: { $if: piano, $then: back-line, $else: full-arc }
    inputs:
      - group: band
        name: accordion
        note: 1x DI
      - group: rhythm
        name: synth
        note: 2x DI
        count: 2
    monitors:
      - group: mono
        note: floor monitor
        placement:
          $if: piano
          $then: { x: -0.175, y: 0.025 }
          $else: { x: -0.05, y: 0.3 }
    visible:
      $?: { lineup: { $includes: 'michal' } }

  - id: nord
    name: { $if: piano, $then: '', $else: Bára }
    instruments: nord ep
    mic:
      left: '50%'
      top: '100%'
    sockets: { $if: piano, $then: 1, $else: 3 }
    addon:
      $if:
        $and:
          - { $not: { lineup: { $includes: vik } } }
          - { $not: piano }
      name: laptop
      position:
        left: 0
        top: 0
        transform: translate(-90%, -50%)
    placement:
      $if: piano
      $then:
        x: 0.55
        y: 0.8
      $else:
        x: 0.5
        y: 0.9
    minWidth: 120
    minHeight: 60
    inputs:
      - group: samples
        name: laptop
        note: 2x DI
        count: 2
        $if: { $not: { lineup: { $includes: vik } } }
      - group: keys
        name: nord bass
        note: 1x DI
      - group: keys
        name: nord ep
        note: 2x DI
        count: 2
      - group: vocals
        name: { $if: piano, $then: vocal Bára piano / nord, $else: vocal Bára nord ep }
        note: own KMS 104 Plus
      - group: vocals
        name: vocal Bára arturia
        note: own OM5
    monitors:
      - iem: true
        group: bara
        stereo: true
        note: own wireless IEM
        $if: { piano: false }

  - id: pavel
    name: Pavel
    instruments: { $if: guitar, $then: gtr / bass, $else: bass }
    mic: true
    sockets: 2
    placement: { $if: piano, $then: back-line, $else: full-arc }
    inputs:
      - group: bass
        name: bass
        note: 1x DI
      - group: vocals
        name: vocal Pavel
        note: own Beta58
    monitors:
      - iem: true
        group: stereo
        stereo: true
        note: 2x XLR in
    visible:
      $?: { lineup: { $includes: pavel } }

  - id: vik
    name: Viktor
    instruments: drum kit
    sockets: 2
    addon:
      name: spd-sx
      position:
        right: 10px
        top: 10px
    placement:
      $if: piano
      $then:
        x: 0.85
        y: 0.55
        a: 90
      $else:
        x: 0.85
        y: 0.65
        a: 80
    minWidth: 200
    minHeight: 170
    inputs:
      - group: drums
        name: kick
        note: pref. Beta52 or D6
      - group: drums
        name: kick IEM
        note: _(double-patch from input 1 / kick)_
      - group: drums
        name: snare 1 top
        note: pref. SM57
      - group: drums
        name: snare 1 bottom
        note: pref. e604
      - group: drums
        name: snare 2 top
        note: pref. SM57
      - group: drums
        name: snare 2 bottom
        note: pref. e604
      - group: drums
        name: hi tom
        note: pref. e604
      - group: drums
        name: floor tom
        note: pref. e604
      - group: drums
        name: overheads
        count: 2
        note: pref. C451 / NT5
      - group: samples
        name: spd-sx
        note: 2x DI
        count: 2
        $if: { lineup: { $includes: vik } }
    monitors:
      - iem: true
        group: vik
        stereo: true
        note: 2x XLR in
    visible:
      $?: { lineup: { $includes: 'vik' } }
inputList:
  groups:
    - drums
    - samples
    - bass
    - keys
    - rhythm
    - band
    - vocals
  note:
    $if: { lineup: { $includes: 'vik' } }
    $then: >-
      Listed preferred mics are just that - preferred, not required.
      We can make it work with any reasonable alternatives. Note that
      even at smaller venues we need to mic the entire drum kit as
      specified below, otherwise our in-ear monitor mixes would fall
      apart.
    $else: >-
      Listed preferred mics are just that - preferred, not required.
      We can make it work with any reasonable alternatives.

monitorList:
  groups:
    - vik
    - bara
    - stereo
    - mono
    - dblpatch
  note: >-
    The band uses wired in-ear monitors. Each musician has their own
    headphone amplifier belt pack on-stage; these will each require
    1-2 additional XLR cables of adequate length from the stagebox.
mainOutputs:
  - name: front fill
    note: if needed
  - name: sub
    note: if possible
  - name: main l&r
    stereo: true
notes:
  - $if: { lineup: { $includes: vik } }
    $then: >-
      The band uses its own Midas M32C rack mixer with a DL32 stagebox.
      Preferred placement of the rack is on stage right behind the drums.
    $else: The band uses its own Midas M32C rack mixer with a DL32 stagebox.
  - $if: { lineup: { $includes: vik } }
    $then: >-
      Please be advised that we need at least 60 minutes for setup and
      soundcheck.
    $else: >-
      Please be advised that we need at least 40 minutes for setup and
      soundcheck.
  - >-
    We're aware that our lineup requires a few too many DI boxes and XLR
    cables; we regularly bring 10 of our own DI boxes, as well as a set
    of XLR cables sufficient to wire up the drum kit and the keyboards.
  - >-
    The mixer is remotely controlled using a laptop at the FOH. On smaller
    venues the mixer can be controlled wirelessly; on larger venues please
    provide a single CAT5 or CAT6 cable from the rack to the FOH.
  - >-
    The band engineer needs adequate table space at the FOH for a laptop and a
    controller plus some wiring - at least around 80x40cm.
  - >-
    Since there is no physical console at the FOH, main house analog output
    can only be provided from the DL32 on stage. A separate feed for subs is
    available and preferred, but not required.
